.alt-banner {
    position: relative;
    display: block;
    background-color: $mavi;
    width: 100%;
    min-height: 100px;
    padding: 10px 0;
    display: flex;  
    align-items: center;
}

.saydam {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.0);
    left: 0;
    top: 0;
    z-index: 2;
}

.sitemap {
    position: relative !important;
    display: block;  
    width: 100%;
    font-family: 'Montserrat';
}

.sitemap span {
    color: $sari;
}

.alt-sayfa-baslik {
    position: relative;
    display: block;
    width: 100%;
    font-size: 26px;
    font-weight: 600;
    color: #FFF;
}

.alt-sayfa-baslik2 {
    position: relative;
    display: block;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #FFF;
}

.alt-sayfa-baslik2 a {
    text-decoration: none;
    color: #FFF;
}

.alt-sayfa-baslik2 a:hover {
    color: $sari;
}

@media screen and (max-width: 415px) {
    .alt-sayfa-baslik{
        position: relative;
        top: 28%;
    }
    .alt-sayfa-baslik2{
        position: relative;
        top: 28%;
    }
}

.bLink {
    @extend %rel;
    width: 100%;
    border-radius: 5px;
    border: 1.5px solid;
    color: #FFF;
    text-align: center;
    padding: 5px 10px;
    text-decoration: none;
    height: 40px;
    margin: 5px 0;
    transition: all 500ms;
    text-decoration: none;
}
.bLink:hover{
    text-decoration: none;
}

.bLinkRed {
    border-color: $kirmizi;
    background-color: $kirmizi;
}

.bLinkRed:hover {
    background-color: #FFF;
    color: $kirmizi;
}

.bLinkBlue {
    border-color: $mavi;
    background-color: $mavi;
}

.bLinkBlue:hover {
    background-color: #FFF;
    color: $mavi;
}


#kurumsal {
    img {
        width: 40%;
        margin: 15px;
    }
}

#urunler {
    .shadow{
        box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
        padding: 20px;
    }
    h2{
        font-size: 20px;
    }
    h6:after{
        content: '';
        position: relative;
        display: block;
        left: 0;
        border-bottom: 3px solid $mavi;
        width: 100px;
        margin-top: 5px;
    }
    .card{
        color: #222;
        transition: all 500ms;
        text-decoration: none;
        overflow: hidden;
        background-color: $mavi;
        margin-bottom: 15px;
    }
    .card:hover{
        border-color: $mavi;
        .card-img-top{
            transform: scale3d(1.7,1.7,1.7);
        }
        .card-body{
            background-color: rgba(0,125,195,0.3);
        }
    }
    .card-img-top{
        transition: all 1s;
        overflow: hidden;
    }
    .card-body{
        overflow: hidden;
        padding: 5px 0;
        text-align: center;
        z-index: 2;
        height: 100%;
        background-color: $mavi;
        transition: background-color 2s;
    }
    .card-title{
        color: #FFF;
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        padding: 5px;
        height: 60px;
        display: flex;
        align-items: center;
    }
    .transparent{
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        background-color: rgba(0,125,195,0.5);
        z-index: 3;
    }
}

#referans{
    .referans{
        margin-bottom: 15px;
        padding: 15px;
        border: 1px solid #CCC;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img{
        max-width: 100%;
        height: 150px;
        object-fit: contain;
    }
}

#blog{
    a{
        color: #222;
        transition: all 500ms;
        text-decoration: none;
        margin-bottom: 15px;
    }
    a:hover{
        color: $mavi;
        border-color: $mavi;
    }
}

#iletisim {
    a {
        color: #222;
        transition: all 500ms;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        color: $mavi;
    }
}