@charset "utf-8";

.ac {
    display: none;
}

.kapa {
    display: block;
}

.gizle {
    display: none;
}

.f-img {
    @extend %rel;
    width: 100%;
}

// Renkler

$anaRenk: #26c6da;
$kirmizi: #9c182f;
$mavi : #007dc3;
$mor: #5d2999;
$sari: #fff200;

.mavi {
    color: $mavi;
}

// Extend

%rel {
    position: relative;
    display: block;
}

#mainSlider{
    @extend %rel;
    width: 100%;
}

#yeni-urunler{
    @extend %rel;
    width: 100%;

    .yeni-urun-bg{
        position: absolute;
        display: block;
        top: 15px;
        z-index: -1;
        max-width: 100%;
    }

    a{
        @extend %rel;
        color: #222;
        text-decoration: none;
        transition: all 300ms;
    }
    a:hover{
        color: $mavi;
    }

    p{
        margin: 15px 0;
        z-index: 2;
    }

    b:after{
        content: '';
        position: absolute;
        display: block;
        margin-top: 10px;
        border-bottom: 4px solid $mavi;
        width: 40px !important;
    }

    .button{
        @extend %rel;
        float: right;
        height: 30px;
        width: 30px;
        img{
            margin-top: -8px;
        }
    }
    .yazi{
        @extend %rel;
        text-align: center;
        margin: 80px 0;
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
        border-bottom: 3px solid $sari;
    }
}

#hizmet{
    @extend %rel;
    width: 100%;
    background-color: #f6f6f6;
    margin-top: -5%;
    padding: 50px 0;
    z-index: -1;
    p{
        margin: 15px 20px;
        font-weight: 600;
    }
}

#referans{
    @extend %rel;
    width: 100%;
}

#refSlider{
    @extend %rel;
}

#hakkinda{
    @extend %rel;
    width: 100%;
    h3{
        font-weight: 700;
        margin: 35px 0;
        font-size: 24px;
        font-weight: 800;
    }
    .sol-baslik{
        border-bottom: 6px solid $sari;
        max-width: 180px;
    }
    .sag-baslik{
        border-bottom: 6px solid $sari;
        max-width: 480px;
        margin: 35px auto;
    }

    b{
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        padding: 20px 0;
    }
    .sag-kutu{
        @extend %rel;
        max-height: 333px;
        margin: 30px;
        padding: 15px;
        background-color: #f0f1f1;
        box-shadow: -1px 2px 3px 0px rgba(0,0,0,0.5);
        img{
            max-height: 300px;
            object-fit: cover;
        }
        .detay{
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            background-color: rgba(255,255,255,0.8);
            padding: 10px;
            font-weight: 600;
            font-size: 15px;
        }
    }
    #blogSlider{
        z-index: 5;
    }
}

#uretim{
    @extend %rel;
    width: 100%;
    h3{
        margin-top: -7.5%;
    }
    h3:before{
        content: '';
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        border-left: 3px solid $mavi;
        width: 3px;
    }
    .yazi{
        margin: 30px 0;
        img{
            @extend %rel;
            float: left;
            width: 20%;
        }
        p{
            @extend %rel;
            width: 80%;
            float: left;
            font-size: 16px;
            font-weight: 600;
            padding: 0 15px;
        }
    }
}

#eBulten {
    @extend %rel;
    width: 100%;
    margin-top: 30px;

    .bulten-wrap {
        @extend %rel;
        max-width: 960px;
        width: 100%;
        margin: 0 auto;
    }

    .yazi {
        @extend %rel;
        text-align: center;
        font-weight: 700;
        line-height: 24px;
        font-size: 24px;
        color: $mavi;
        width: 15%;
        margin-top: 25px;
        float: left;
    }

    .resim {
        @extend %rel;
        float: left;
        width: 20%;
        height: 100px;
    }

    img {
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 150px;
        height: 150px;
        object-fit: contain;
    }

    input {
        @extend %rel;
        float: left;
        width: 20%;
        height: 30px;
        line-height: 30px;
        margin-top: 35px;
        border: 1px solid $mavi;
        border-radius: 30px;
        padding-left: 15px;
        color: #222;
        margin-right: 40px;
        outline: none;
    }

    input:focus {
        border-color: #222;
    }

    input:focus::placeholder {
        color: #222;
    }

    input::placeholder {
        color: $mavi;
        font-weight: 700;
    }

    button {
        @extend %rel;
        float: left;
        width: 10%;
        height: 30px;
        line-height: 30px;
        margin-top: 35px;
        outline: none;
        border: none;
        background: none;
        color: $mavi;
        font-weight: 700;
        font-size: 16px;
        font-style: italic;
        transition: all 500ms;

        .nokta-wrap {
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            left: 0;
            top: 5px;
        }

        .nokta {
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            background-color: $mavi;
            border-radius: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .cember {
            position: absolute;
            display: none;
            width: 20px;
            height: 20px;
            border: 2px solid $mavi;
            border-radius: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all 500ms;
        }
    }

    button:hover {
        transition: all 500ms;

        .cember {
            display: block;
        }
    }
}

footer {
    text-align: center;
    background: url('/resim/ana/footer-bg.jpg') no-repeat top;
    background-size: cover;

    span {
        @extend %rel;
        font-size: 22px;
        letter-spacing: 8px;
        font-weight: 700;
        padding-top: 15px;
        color: #FFF;
    }

    .social-wrap {
        @extend %rel;
        margin: 10px auto;

        a {
            position: relative;
            display: inline-block;
            color: #222;
            background-color: #FFF;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            margin: 0 5px;
            transition: all 800ms;
            box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);

            i {
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        a:hover {
            background-color: $mavi;
            color: #FFF;
            transform: rotate(360deg);
        }
    }

    .adres{
        color: #FFF;
        font-weight: 500;
        margin: 20px 0;
        font-size: 18px;

        a{
            @extend %rel;
            height: 40px;
            line-height: 40px;
            width: 150px;
            text-align: center;
            color: #222;
            background-color: #FFF;
            box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
            text-decoration: none;
            border-radius: 15px;
            margin: 0 auto;
            transition: all 500ms;
            font-size: 16px;
            font-weight: 700;
        }
        a:hover{
            background-color: $mavi;
            color: #FFF;
        }

    }

    p {
        font-weight: 700;
        font-size: 13px;
        background-color: #EEE;
        margin: 0;
        padding: 20px 0;
    }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 767px) {
    .ac {
        display: block;
    }

    .kapa {
        display: none;
    }
    #hakkinda{
        .sag-kutu{
            max-height: 400px;
            .detay{
                position: relative;
                background-color: none;
            }
        }
    }
    #referans{
        padding: 0 50px;
    }
    #uretim{
        h3{
            margin: 0;
            text-align: center;
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 415px) {
    #eBulten {
        .yazi {
            width: 50%;
        }

        input {
            width: 90%;
            padding: 0 15px;
            margin: 10px 5%;
        }

        button {
            width: 30%;
            margin: 5px 35%;
        }
    }
}

@import 'altsayfa';
@import 'rightbar';