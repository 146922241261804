#rightbar {
    position: fixed;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    z-index: 1000;
}
#rightbar a {
    font-size: 20px;
    background-color: rgba(0,0,0,0.3);
    position: relative; 
    display:block; 
    box-sizing: border-box;
    text-align:center;
    padding: 10px;
    margin-bottom:2px;
    color: #fff !important;
}
#rightbar a:hover{background-color: rgba(0,0,0,0.5);}
#rightbar .wp {color: #fff;}
#rightbar .tel {color: #fff;}
#rightbar .mail {color: #fff;}

